import styled, { keyframes, css } from 'styled-components';
import {Box} from "@mui/material";

const slideInKeyframes = keyframes`
  to {
    transform: rotate(0deg) scale(1.0, 1.0);
    opacity: 1;
  }
`

export const StyledBox = styled(Box)`
  animation: ${slideInKeyframes} 1s ease-out;
  animation-fill-mode: forwards;
  animation-delay: ${props => props.animationDelay || '500ms'}
`
