import {createTheme} from '@mui/material';
import abrilRegular from '../../assets/fonts/AbrilFatface-Regular.ttf';
import myriadRegular from '../../assets/fonts/MyriadPro-Regular.ttf';
import myriadBold from '../../assets/fonts/MyriadPro-Bold.ttf';
import myriadSemiBold from '../../assets/fonts/MyriadPro-Semibold.ttf';


export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1386
    },
  },
  typography: {
    body3: {
      fontSize: '18px',
      lineHeight: '25px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `        
        @font-face {
          font-family: 'Abril-Regular';
          src: url(${abrilRegular}) format('truetype');
        }
        
        @font-face {
          font-family: 'Myriad-Regular';
          src: url(${myriadRegular}) format('truetype');
        }
        
        @font-face {
          font-family: 'Myriad-SemiBold';
          src: url(${myriadSemiBold}) format('truetype');
        }
        
        @font-face {
          font-family: 'Myriad-Bold';
          src: url(${myriadBold}) format('truetype');
        }
        
        body {
        	background-color: #E0F7FF;
        	overflow-x: hidden;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          fontFamily: 'Myriad-Regular',
          border: '2px solid #FC3485',
          borderRadius: '50px',
          textTransform: 'none',
          padding: '5px 30px',
          color: '#fff',
          ":hover": {
            border: '2px solid #FC3485',
            backgroundColor: 'transparent'
          }
        },
        contained: {
          fontFamily: 'Myriad-Regular',
          fontSize: '23px',
          backgroundColor: '#00BCA3',
          borderRadius: '32px',
          textTransform: 'none',
          padding: '12px 64px',
          ":hover": {
            backgroundColor: '#00BCA3'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '66px',
          fontFamily: 'Abril-Regular',
          color: '#fff'
        },
        h2: {
          fontSize: '48px',
          fontFamily: 'Abril-Regular',
        },
        h3: {
          fontSize: '32px',
          fontFamily: 'Myriad-Bold',
        },
        h4: {
          fontSize: '45px',
          fontFamily: 'Abril-Regular',
          color: '#fff'
        },
        body1: {
          fontSize: '22px',
          lineHeight: '42px',
          fontFamily: 'Myriad-Regular',
        },
        body2: {
          fontSize: '19px',
          lineHeight: '31px',
          fontFamily: 'Myriad-Regular',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Myriad-Regular',
          fontSize: '22px',
          color: '#B5B8C6'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: 'Myriad-SemiBold',
          fontSize: '18px',
          padding: '8px 0'
        }
      }
    }
  },
});
