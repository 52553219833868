import {Box, Button, CssBaseline, TextField, ThemeProvider, Typography} from "@mui/material";
import {theme} from "./utils/theme";
import logoImage from "./assets/images/logo.png";
import cloudImage from "./assets/images/cloud.png";
import bulbImage from "./assets/images/bulb.png";
import startedIcon from "./assets/images/icon-started.png";
import processIcon from "./assets/images/icon-process.png";
import ideaIcon from "./assets/images/icon-idea.png";
import emailIcon from "./assets/images/icon-email.svg";
import dotsImage from "./assets/images/dots.svg";
import {BoxMoveUp} from "./components/box-move-up";
import {useEffect, useRef, useState} from "react";
import {useInViewport} from "react-in-viewport";
import {BoxSlideIn} from "./components/box-slide-in";
import emailjs from '@emailjs/browser';

const emailValidationRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

export const App = () => {
  const animatedSectionRef = useRef();
  const {
    inViewport,
  } = useInViewport(animatedSectionRef);
  const [isAnimated, setIsAnimated] = useState(false);
  const [animationDirection, setAnimationDirection] = useState(null);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false
  })


  useEffect(() => {
    if (inViewport && !isAnimated) {
      setIsAnimated(true);
    }
  }, [inViewport, isAnimated])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = () => {
    const errorFields = [];

    if (formValues.name === '') {
      errorFields.push('name');
    }

    if (formValues.message === '') {
      errorFields.push('message');
    }

    if (formValues.email === '' || !emailValidationRegex.test(formValues.email)) {
      errorFields.push('email');
    }

    const formErrorsCopy = { ...formErrors };

    Object.keys(formErrorsCopy).forEach(key => {
      if (errorFields.includes(key)) {
        formErrorsCopy[key] = true;
      } else {
        formErrorsCopy[key] = false;
      }
    })

    setFormErrors(formErrorsCopy);

    if (errorFields.length === 0) {
      // send email
    }
  }

  const handleScroll = () => {
    setAnimationDirection('up');
    // if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    //   setAnimationDirection('up');
    // } else {
    //   setAnimationDirection('down');
    // }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Box backgroundColor="#291E31" position="relative">
        <Box width="170px" height="930px" position="absolute" left={0} top="-75px" zIndex={1} sx={{
          background: "transparent linear-gradient(83deg, #221928 0%, #3A2848 100%) 0% 0% no-repeat padding-box",
          transform: "skewY(-25deg)",
          borderBottomRightRadius: "40px"
        }}/>
        <Box maxWidth="1386px" margin="0 auto" position="relative" zIndex={1}>
          <Box component="header">
            <Box display="flex" alignItems="center" justifyContent="space-between"
                 padding={{xs: "15px", sm: "35px 40px 0 40px"}}>
              <Box component="img" src={logoImage} width={{xs: "180px", sm: "auto"}}/>
              <Button variant="outlined"
                      onClick={() => document.querySelector('#contact').scrollIntoView({behavior: 'smooth'})}>
                Let's Start
              </Button>
            </Box>
          </Box>

          <Box display="flex" justifyContent={{xs: "center", md: "flex-end"}} paddingRight={{xs: "0px", md: "130px"}}>
            <Box maxWidth="726px" margin={{xs: "60px 0 200px 0", sm: "60px 0"}} padding="0 15px">
              <Typography variant="h1" fontSize={{
                xs: '46px',
                sm: '66px'
              }}>
                We Visualize your <Box component="span" fontFamily="Myriad-Bold" color="#EDB000">idea,</Box> so you can
                easier find investors.
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent={{xs: "center", sm: "flex-end"}} position="relative"
               padding={{xs: "0 15px", sm: "0 15px 0 0", md: "0 130px 0 0"}}>
            <Box position="absolute" right="20px" top="-80px">
              <img src={dotsImage}/>
            </Box>
            <Box backgroundColor="#fff"
                 width={{xs: "100%", sm: "520px", md: "762px"}}
                 borderRadius="68px"
                 padding={{xs: "50px", md: "75px 90px"}}
                 display="flex"
                 position="relative"
                 flexDirection="column"
                 gap={{xs: "25px", sm: "50px"}}
                 alignSelf="flex-end">
              <Typography variant="h2" fontSize={{xs: '40px', sm: '48px'}}>
                Why Design Demo?
              </Typography>
              <Typography variant="body1">
                Before taking the step to invest in technical scoping and full development of your application,
                a clickable design presentation is a great way of highlighting the functions as well as the look and
                feel of an application,
                to gather interest or funding.
              </Typography>
              <BoxMoveUp position="absolute" left={{xs: 0, sm: "-280px", md: "-530px"}}
                         top={{xs: "-175px", sm: "100px", md: "5px"}} display={{xs: "flex", sm: "block"}}
                         justifyContent="center" width={{xs: "100%", sm: "auto"}} zIndex={1}
                         animationDirection={animationDirection}>
                <Box component="img" src={cloudImage} width={{xs: "320px", sm: "340px", md: "auto"}}/>
              </BoxMoveUp>
              <Box position="absolute" left={{xs: 0, sm: "-170px", md: "-305px"}} top={{xs: "-130px", sm: "125px"}}
                   display={{xs: "flex", sm: "block"}} justifyContent="center" width={{xs: "100%", sm: "auto"}}>
                <Box component="img" src={bulbImage} width={{xs: "100px", sm: "130px", md: "auto"}}/>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box position="absolute" bottom={0} left={0} width="100%" height="150px" backgroundColor="#3A264A"/>
      </Box>

      <Box backgroundColor="#3A264A" padding="130px 0 120px 0">
        <Box maxWidth="1386px" margin="0 auto" padding={{xs: "0 15px", sm: 0}} display="flex" justifyContent="center"
             flexDirection={{xs: "column", sm: "row"}} alignItems={{xs: "center"}} ref={animatedSectionRef}>
          <Box width={{xs: "100%", sm: "428px"}}
               height={{sm: "420px", md: "354px"}}
               borderRadius="60px"
               backgroundColor="#fff"
               position="relative"
               padding={{xs: "90px 75px 40px 75px", sm: "145px 75px 15px 75px"}}
               textAlign="center"
               display="flex"
               flexDirection="column"
               gap="20px"
               marginBottom={{xs: "70px", sm: 0}}
               marginRight={{xs: 0, sm: "-20px"}}>
            {
              isAnimated && (
                <BoxSlideIn position="absolute" top="-80px" left="0px" width="100%" display="flex" justifyContent="center"
                            sx={{
                              transform: "rotate(-10deg) scale(1.5, 1.5)",
                              opacity: 0
                            }}>
                  <Box component="img" width={{xs: "250px", sm: "auto"}} src={startedIcon}/>
                </BoxSlideIn>
              )
            }
            <Typography variant="h3">
              Let's Get Started
            </Typography>
            <Typography variant="body2">
              Complete the form below to connect with our team and get started immediately.
            </Typography>
          </Box>

          <Box width={{xs: "100%", sm: "428px"}}
               height={{sm: "auto", md: "390px"}}
               borderRadius="60px"
               backgroundColor="#fff"
               position="relative"
               padding={{xs: "100px 35px 40px 35px", sm: "145px 35px 15px 35px"}}
               textAlign="center"
               display="flex"
               flexDirection="column"
               gap="20px"
               boxShadow="23px 39px 122px #18142F73"
               zIndex={1}
               marginBottom={{xs: "100px", sm: 0}}
               marginTop="70px">
            {
              isAnimated && (
                <BoxSlideIn position="absolute" top="-100px" left="0px" width="100%" display="flex"
                            justifyContent="center" sx={{
                  transform: "rotate(-10deg) scale(1.5, 1.5)",
                  opacity: 0
                }} animationDelay="1s">
                  <Box component="img" width={{xs: "180px", sm: "auto"}} src={processIcon}/>
                </BoxSlideIn>
              )
            }
            <Typography variant="h3">
              Creative Process Begins
            </Typography>
            <Typography variant="body2">
              Designers begin, with your insight, a consultative discovery to
              develop the overall vision for the application to meet your business goals.
            </Typography>
          </Box>

          <Box width={{xs: "100%", sm: "428px"}}
               height={{sm: "auto", md: "354px"}}
               borderRadius="60px"
               backgroundColor="#fff"
               position="relative"
               padding={{xs: "100px 35px 40px 35px", sm: "145px 35px 15px 35px"}}
               textAlign="center"
               display="flex"
               flexDirection="column"
               gap="20px"
               marginLeft={{xs: 0, sm: "-20px"}}
               marginTop="20px">
            {
              isAnimated && (
                <BoxSlideIn position="absolute" top="-80px" left="0px" width="100%" display="flex" justifyContent="center"
                            sx={{
                              transform: "rotate(-10deg) scale(1.5, 1.5)",
                              opacity: 0
                            }}
                            animationDelay="1.5s">
                  <img src={ideaIcon}/>
                </BoxSlideIn>
              )
            }
            <Typography variant="h3">
              Your Idea Comes to Life
            </Typography>
            <Typography variant="body2">
              Your idea becomes a simulation of the final experience including
              the sequence of screens/pages and how a user navigates them in real time.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box backgroundColor="#3A264A" id="contact">
        <Box backgroundColor="#1B1124" borderRadius={{xs: "65px 65px 0px 0px", sm: "131px 131px 0px 0px"}} paddingTop={{xs: "60px", sm: "122px"}}>
          <Box textAlign="center" display={{xs: "block", sm: "none"}} margin="0 auto 40px auto" p="0 15px">
            <Button variant="contained"
                    onClick={() => window.open('https://projects.invisionapp.com/share/RF136LNEA7YJ#/screens')}>Clickable
              demo preview</Button>
          </Box>
          <Box maxWidth="1386px" margin="0 auto" display="flex" justifyContent="center">
            <Box backgroundColor="#F3F4F6" borderRadius="80px" display="flex" width="100%">
              <Box position="relative"
                   display={{xs: "none", sm: "block"}}
                   padding={{
                     sm: "0 15px",
                     md: "0 100px"
                   }}
                   marginTop="-180px">
                <Box>
                  <iframe width="442" height="880" src="//invis.io/RF136LNEA7YJ" frameBorder="0"/>
                </Box>
                <Box width="220px" textAlign="center" margin="10px auto 40px auto">
                  <Box component="span" color="#B5B8C6" fontSize="17px">
                    Click around to experience clickable demo
                  </Box>
                </Box>
              </Box>

              <Box padding={{
                xs: "50px",
                sm: "50px 30px 0 0",
                md: "75px 100px 75px 0"
              }} flex={1} display="flex" flexDirection="column" gap={{xs: "50px", md: "100px"}}>
                <Box display="flex" justifyContent="space-between" alignItems={{xs: "flex-start", md: "flex-end"}}
                     flexDirection={{xs: "column", md: "row"}} gap={{xs: "15px", md: "0px"}}>
                  <Box component="span" fontFamily="Myriad-Regular" fontSize={{xs: "38px", md: "45px"}}
                       lineHeight="61px" width={{xs: "auto", md: "200px"}}>
                    What is your idea?
                  </Box>
                  <Box display="flex" gap="15px" paddingBottom="10px">
                    <Box width="48px" height="48px" boxShadow="6px 10px 18px #211C3E2B" borderRadius="12px"
                         display="flex" alignItems="center" justifyContent="center">
                      <img src={emailIcon}/>
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Box component="span" color="#B5B8C6" fontFamily="Myriad-Bold" fontSize="18px">E-mail</Box>
                      <Box component="a" href="mailto:contact@design-demos.com" color="#00BCA3"
                           fontFamily="Myriad-Regular" fontSize="18px"
                           sx={{textDecoration: 'none'}}>contact@design-demos.com</Box>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap="60px">
                  <Box display="flex" flexDirection="column" gap="30px">
                    <TextField InputLabelProps={{shrink: true}}
                               variant="standard"
                               label="Enter Your Name"
                               error={formErrors.name}
                               value={formValues.name}
                               onChange={e => setFormValues(prevValues => ({ ...prevValues, name: e.target.value}))}/>
                    <TextField InputLabelProps={{shrink: true}}
                               variant="standard"
                               label="Your E-mail"
                               error={formErrors.email}
                               value={formValues.email}
                               onChange={e => setFormValues(prevValues => ({ ...prevValues, email: e.target.value}))}/>
                    <TextField InputLabelProps={{shrink: true}}
                               variant="standard"
                               label="Write To Us"
                               multiline
                               error={formErrors.message}
                               value={formValues.message}
                               onChange={e => setFormValues(prevValues => ({ ...prevValues, message: e.target.value}))}/>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button variant="contained" onClick={handleSubmit}>Send a message</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box maxWidth="1386px" margin="0 auto">
            <Box textAlign="center" margin="90px 0 40px 0">
              <Typography variant="h4">
                Our <Box component="span" fontFamily="Myriad-Regular" color="#EDB000">Reviews</Box>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems={{xs: "center", sm: "normal"}} gap="15px"
                 flexDirection={{xs: "column", sm: "row"}} padding={{xs: "0 15px", sm: 0}}>
              <Box borderRadius="46px" backgroundColor="#fff" padding="45px 40px" width={{xs: "100%", sm: "440px"}}>
                <Box>
                  <Box component="span" fontFamily="Myriad-Bold" fontSize="18px" color="#727BA2">Ben Brockman</Box>
                </Box>
                <Box marginBottom="18px">
                  <Box component="span" fontFamily="Myriad-Regular" fontSize="18px" color="#727BA2">CMO, WithYou</Box>
                </Box>
                <Typography variant="body3">
                  "The design team was always exceptional, quick to respond and always listened to our needs. 5/5, I
                  would recommend them to anyone."
                </Typography>
              </Box>

              <Box borderRadius="46px" backgroundColor="#fff" padding="45px 40px" width={{xs: "100%", sm: "440px"}}>
                <Box>
                  <Box component="span" fontFamily="Myriad-Bold" fontSize="18px" color="#727BA2">Jason Harper</Box>
                </Box>
                <Box marginBottom="18px">
                  <Box component="span" fontFamily="Myriad-Regular" fontSize="18px" color="#727BA2">
                    Principal Electrical Engineer, Argonne National Laboratory
                  </Box>
                </Box>
                <Typography variant="body3">
                  "Design team were very easy to work with and delivered a very nice design."
                </Typography>
              </Box>

              <Box borderRadius="46px" backgroundColor="#fff" padding="45px 40px" width={{xs: "100%", sm: "440px"}}>
                <Box marginBottom="18px">
                  <Box component="span" fontFamily="Myriad-Bold" fontSize="18px" color="#727BA2">Name Surname</Box>
                </Box>
                <Typography variant="body3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vel ullamcorper lorem. Nunc tortor
                  turpis,
                  fermentum ut magna nec, iaculis eleifend arcu. Sed blandit arcu non turpis rutrum facilisis. In leo
                  magna,
                  molestie nec dolor in, efficitur accumsan lacus.
                </Typography>
              </Box>
            </Box>

            <Box component="footer" padding="0 25px" marginTop="45px">
              <Box borderTop="2px solid rgba(255, 255, 255, 0.15)" padding="50px 0" textAlign="center">
                <Box component="span" fontFamily="Myriad-Regular" fontSize="18px" color="#fff">
                  © 2023 Design-Demos. All rights reserved.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
