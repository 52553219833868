import styled, { keyframes, css } from 'styled-components';
import {Box} from "@mui/material";

const moveUpKeyframes = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-4000px);
  }
`

const moveDownKeyframes = keyframes`
  from {
    transform: translateY(-1000px);
  }
  to {
    transform: translateY(0px);
  }
`

const moveAnimation = props => {
  if (props.animationDirection === 'up') {
    return css`
    ${moveUpKeyframes} 4s ease-in;
   `
  } else if (props.animationDirection === 'down') {
    return css`
    ${moveDownKeyframes} 2s ease-in;
   `
  } else {
    return 'none'
  }
}

export const StyledBox = styled(Box)`
  animation: ${moveAnimation};
  animation-fill-mode: forwards;
`
